import(/* webpackMode: "eager" */ "/codebuild/output/src4119790117/src/frontend/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsent"] */ "/codebuild/output/src4119790117/src/frontend/components/global/cookie-consent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalytics"] */ "/codebuild/output/src4119790117/src/frontend/components/global/google-analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src4119790117/src/frontend/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src4119790117/src/frontend/lib/providers/next-theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4119790117/src/frontend/lib/providers/state-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupabaseUserProvider"] */ "/codebuild/output/src4119790117/src/frontend/lib/providers/supabase-user-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4119790117/src/frontend/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserrat\"}");
