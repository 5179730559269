'use client';

import * as React from 'react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { type ThemeProviderProps } from 'next-themes/dist/types';
export function ThemeProvider({
  children,
  ...props
}: ThemeProviderProps) {
  return <NextThemesProvider {...props} data-sentry-element="NextThemesProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="next-theme-provider.tsx">{children}</NextThemesProvider>;
}